import type { MaybeComputedRef } from '@vueuse/shared'

const TWITTER_SITE = '@therappx'
const URI = 'https://appguide.ca'

export function useSeo(meta: {
  title?: MaybeComputedRef<string>
  description?: MaybeComputedRef<string>
  image?: {
    url?: MaybeComputedRef<string>
    alt?: MaybeComputedRef<string>
    width?: MaybeComputedRef<number | string>
    height?: MaybeComputedRef<number | string>
  }
}) {
  const route = useRoute()
  const { t } = useI18n({ useScope: 'global' })
  const i18nHead = useLocaleHead({
    addSeoAttributes: true,
    addDirAttribute: true,
  })

  return computed(() => {
    const title = meta?.title ? resolveUnref(meta.title) : ''
    const description = meta?.description ? resolveUnref(meta.description) : t('meta.description')
    const image_url = meta?.image?.url ? resolveUnref(meta.image.url) : t('meta.image.url')
    const image_alt = meta?.image?.alt ? resolveUnref(meta.image.alt) : t('meta.image.alt')
    const image_width = meta?.image?.width ? resolveUnref(meta.image.width) : 1200
    const image_height = meta?.image?.height ? resolveUnref(meta.image.height) : 630

    const default_title = ['index___fr', 'index___en'].includes(String(route.name)) ? t('meta.title') : 'AppGuide'
    const exclude_title_tail = ['request-review___fr', 'request-review___en'].includes(String(route.name))

    let title_suffix = 'AppGuide'
    if (String(route.name).startsWith('developer'))
      title_suffix = t('meta.title_suffix.dev')

    return {
      title: title ? `${title}${exclude_title_tail ? '' : ` - ${title_suffix}`}` : default_title,
      meta: [
        ...(i18nHead.value.meta || []),
        { name: 'description', content: description },

        // Open Graph
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${URI}${route.fullPath}` },
        { property: 'og:image', content: image_url },
        { property: 'og:image:width', content: image_width },
        { property: 'og:image:height', content: image_height },
        { property: 'og:image:alt', content: image_alt },

        // Twitter
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: image_url },
        { name: 'twitter:site', content: TWITTER_SITE },
      ],
      link: [
        ...(i18nHead.value.link || []),
      ],
    }
  })
}
